import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import Newsletters from './Newsletters';
import Signin from './Signin';
import Settings from './Settings';
import Logout from './Logout';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase';
import shark from './shark.svg';

require("firebase/firestore");
var firebaseConfig = {
  apiKey: "AIzaSyCbisx7sAB_uN5xi-rVcYrrhQrd5flPT3Q",
  authDomain: "readpostapp.firebaseapp.com",
  databaseURL: "https://readpostapp.firebaseio.com",
  projectId: "readpostapp",
  storageBucket: "readpostapp.appspot.com",
  messagingSenderId: "436227814324",
  appId: "1:436227814324:web:04bfd987f863b79f8f38e1"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);


function App(props) {
  const [user, setUser] = useState(null)
  const [userInfo, setUserInfo] = useState(null)

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setUser(user)

    } else {
      setUser(null)
    }
  });
  useEffect(() => {
      if (user == null) return;
      firestore.doc(`userread/${user.uid}`).get().then(doc => {
        setUserInfo(doc.data())
      })


  }, [user])
  return (
    <Router>

      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top py-1 border-bottom mb-2">
      <div className="container d-flex flex-column flex-md-row justify-content-between">
            <Link to="/" className="py-2 navbar-brand">
              <img src={shark} alt=" Logo" width="30" height="30" style={{verticalAlign: 'baseline'}} className="d-inline-block align-top" /> ReadPost
            </Link>

            <div className="collapse navbar-collapse container">
              <ul className="navbar-nav">
            <li className="nav-item active">
              <Link to="/" className="nav-link">Home</Link>
            </li>
            </ul>

            <ul class="nav navbar-nav ml-auto">

              { user == null ? <li className="nav-item"> <Link to="/signin" className="nav-link">Sign in</Link></li> :
                <>
                <li className="nav-item"><code className="email-address">{userInfo && userInfo.email}</code></li>
                <li className="nav-item"><Link to="/settings" className="nav-link">Settings</Link></li>
                <li className="nav-item"><Link to="/logout" className="nav-link">Logout</Link></li>
                </>

              }
            </ul>
        </div>
      </div>
    </nav>
      <div className="">

        <div>

        </div>
        <Switch>
          <Route exact path="/">
            <Newsletters user={user} firebase={firebase} />
          </Route>
          <Route exact path="/home">
            <Home user={user} firebase={firebase} userInfo={userInfo} />
          </Route>
          <Route path="/newsletters">
            <Newsletters user={user} firebase={firebase} />
          </Route>
          <Route path="/newsletter/:id">
            <Newsletters user={user} firebase={firebase} />
          </Route>
          <Route path="/settings">
            <Settings firebase={firebase} user={user} userInfo={userInfo} />
          </Route>
          <Route path="/signin">
            <Signin firebase={firebase} user={user} />
          </Route>
          <Route path="/logout">
            <Logout firebase={firebase} />
          </Route>
          {/* <Route exact path="/">
            {loggedIn ? <Redirect to="/dashboard" /> : <PublicHomePage />}
          </Route> */}
        </Switch>
        {/* <Match path="newsletters" component={Newsletters}  />
        <Match path="signin" component={Newsletters}  /> */}
        {/* <Match path="reports" component={MapReportList}  /> */}
        {/* <Match path="reports/edit/{id}" component={MapEditor}  /> */}
      </div>

    </Router>
  );
}

//
// <Redirect
// to={{
//   pathname: "/login",
//   search: "?utm=your+face",
//   state: { referrer: currentLocation }
// }}
// />


ReactDOM.render( <App />, document.getElementById('root'));

//Shark Surfing by Wolff from the Noun Project
//Robot by iconcheese from the Noun Project
//Bike by Brad Avison from the Noun Project
//Mountains by Flatart from the Noun Project