import { useHistory } from 'react-router-dom';
// import './Home.css';

function Settings(props) {
  console.log(props)
  const history = useHistory()

  if(props.user == null) {
    history.push('signin')
    return (<div></div>)

  }

  return (
    <div className="Settings">
      <header className="App-header">
        Settings...
        Your email address: <p className="user-select-all">{props.userInfo && props.userInfo.email}</p>
      </header>
    </div>
  );
}

export default Settings;



