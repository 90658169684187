// import './signin.css';
import {
  useHistory
} from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {useEffect} from 'react'

function Signin(props) {
  var firebase = props.firebase;
  let history = useHistory();

  if (props.user != null) history.push("/")

  // Confirm the link is a sign-in with email link.
if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  var email = window.localStorage.getItem('emailForSignIn');
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt('Please provide your email for confirmation');
  }
  // The client SDK will parse the code from the link for you.
  firebase.auth().signInWithEmailLink(email, window.location.href)
    .then(function(result) {
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
      console.log(result)
      history.push('/home')
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
    })
    .catch(function(err) {
      console.log(err)
    });
}

    // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => { console.log('logged in')}
    },
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        forceSameDevice: false
      }
    ]
  };
  useEffect(() => {
    console.log('useffect', document.getElementById("ui-sign-in-email-input"))

    function findEmail() {
      // hacky yes, but need to find the email input and attach a listener so we can save email to local storage
      if (document.getElementById("ui-sign-in-email-input") == null) {
        setTimeout(findEmail, 200)
        return;
      }
      document.getElementById("ui-sign-in-email-input").addEventListener('change', (event) => {
        window.localStorage.setItem('emailForSignIn', event.target.value);
      })
    }
    findEmail()
  })

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Signin
        </p>
        <div>
        <p>Please sign-in:</p>
           <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </div>

      </header>
    </div>
  );
}




export default Signin;