import './Newsletters.css';
import React, { useState, useEffect } from "react";
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";

import dompurify from 'dompurify';
import moment from 'moment';
import notArchivedSvg from 'bootstrap-icons/icons/archive.svg'
import archivedSvg from 'bootstrap-icons/icons/archive-fill.svg'

const sanitizer = dompurify.sanitize;

function LettersList(props) {

  if (props.letters.length === 0) {
    return (

      <div className="Home">
        <p style={{marginTop:"100px"}}>
      <strong >
        No new items to read.
        </strong>
        </p>
        <p>
        If you are new to readpost, you can  <Link to="/signin">Sign in</Link>.

        If you have already signed in you can start receiving newsletters by sending them to your <Link to="/settings">readpost email address</Link>
        </p>
        <p>
        (newsletter explore page coming soon)
        </p>

    </div>


    )
  }

  return props.letters.map(l => {

    console.log(l)
    var timeago = moment(l.date.seconds * 1000).fromNow();
    var datestr = moment(l.date.seconds * 1000).format("dddd, MMMM Do YYYY, h:mm:ss a")

    return (


      <div className="container no-gutters mb-2 border-bottom pb-1" key={l.id}>
        <div className="row no-gutters">
          <div className="col-9">
          <Link to={"/newsletter/"+l.id} className="subject-links"><h5 className="mb-0">{l.subject}</h5></Link>
          </div>
          <div className="col-3 text-right">
            <small title={datestr}>{timeago}</small>
          </div>
        </div>
        <div className="row no-gutters" style={{marginBottom: "10px"}} >
          <div className="col-12">
            <small>{l.from.value[0].name || l.from.text}</small>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12 no-gutters">
            <p className="mb-0 pb-0">{l.text.substring(0,100)}</p>
          </div>
        </div>
      </div>

    )
  })

}

function SingleNewsletterMessage(props) {
  const history = useHistory()

  var message = props.message;
  console.log(message)

  if (!message) {
    return (
      <div>Loading</div>
    )
  }


  var timeago = moment(message.date.seconds * 1000).fromNow();
  var datestr = moment(message.date.seconds * 1000).format("dddd, MMMM Do YYYY, h:mm:ss a")
  var dirtyHtml = message.html || message.textAsHtml;


  var archiveSvg = message.archived ? archivedSvg : notArchivedSvg;

  function archive() {
    props.archiveHandler(message.id, !message.archived, () => {
      history.push("/")
    })
  }

  return (
   <div className="container ">
      <div className="row mt-3">
        <div className="col-11">
          <h3 className="mb-1">{message.subject}</h3>
        </div>
        <div className="col-1 text-right">
          <button type="button" className="btn btn-sm btn-outline-none" onClick={archive}>
            <img title="Archive" alt="Archive" src={archiveSvg} />
          </button>
        </div>
      </div>
      <div className="row" style={{marginBottom: "10px"}} >
        <div className="col-12">
          <small>{message.from.value[0].name || message.from.text}</small> - <small title={datestr}>{timeago}</small>
        </div>
      </div>
      <div className="row ">
        <div className="col-12 border rounded-sm">
          <p className="mb-0 pb-0" dangerouslySetInnerHTML={{__html: sanitizer(dirtyHtml)}}></p>
        </div>
      </div>
   </div>

  )

}

function Newsletters(props) {
  const firestore = props.firebase.firestore();
  let { id } = useParams();
  const [letters, setLetters]  = useState([])
  const [message, setMessage]  = useState(null)


  useEffect(() => {
    if (props.user == null) return

    if (id != null) {
      console.log('id set', id)
      var messageRef = firestore.doc(`incoming/${props.user.uid}/messages/${id}`)
      messageRef.get().then(function (docRef) {
        var docData = docRef.data()
        docData.id = docRef.id
        setMessage(docData)
      });
    } else {
      var messages = firestore.collection(`incoming/${props.user.uid}/messages`)
        .where('archived', '==', false)
        .orderBy("serverTime", "desc").limit(50)
      messages.get().then(function (querySnapshot) {
        var messageList = querySnapshot.docs.map(doc => {
            console.log(doc.id, ' => ', );
            var docData = doc.data()
            docData.id = doc.id
            return docData
        });
        setLetters(messageList)
        setMessage(null)
      });
    }
  }, [props.user, id])


  function archiveHandler(messageId, status, cb) {
    var messageRef = firestore.doc(`incoming/${props.user.uid}/messages/${messageId}`)
    messageRef.update({archived: status}).then(r => {
      setMessage({...message, archived:status})
      if (cb) cb();
    })
  }

  return (
    <div className="container">
      <div className="container-lg">
        { id ? <SingleNewsletterMessage message={message} archiveHandler={archiveHandler} /> :
          <LettersList letters={letters} />
        }
      </div>
    </div>
  );
}

export default Newsletters;
