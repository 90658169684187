import { useHistory } from 'react-router-dom';
import './Home.css';

function Home(props) {
  console.log(props)
  const history = useHistory()

  if(props.user == null) {
    history.push('signin')
    return (<div></div>)

  }

  return (
    <div className="Home">
      <header className="App-header">
        Home
      </header>
    </div>
  );
}

export default Home;



