// import './signin.css';
import {
  useHistory
} from "react-router-dom";


function Logout(props) {
  const history = useHistory()


  props.firebase.auth().signOut().then(function() {
      history.push("/")
  }).catch(function(err) {
    console.log(err);
  });


  return (
    <div className="App">
      <header className="App-header">
        <p>
          Lougout
        </p>
      </header>
    </div>
  );
}




export default Logout;